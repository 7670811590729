const environment = {
	production: false,
	client_id: 'amzn1.application-oa2-client.9801cbca613c4c449c2bfa14d939c158',
	public_key: '1XuWhNMCdX8rQIn5n5GIqd0NXgympxb9P2kVwVof',
	devURL: 'https://dev.bontu-dev.io/',
	chat: {
		host: 'https://wchat.freshchat.com',
		token: '83ff6496-0c31-41db-9072-3f539aa3cca0'
	},
	cognitoConfig: {
	  Auth: {
		identityPoolId: 'us-east-1:fda80cdc-17e8-4142-9815-e9cee4f8887a',
		region: 'us-east-1',
		identityPoolRegion: 'us-east-1',
		userPoolId: 'us-east-1_OoBiBYTgO',
		userPoolWebClientId: 'a62v64c5kjg5ssjafdipb2vc',
		oauth: {
		  domain: 'pre-prod-userappauth.auth.us-east-1.amazoncognito.com',
		  redirectSignIn: 'https://www.userapp-r.bontu-dev.io/callback',
		  redirectSignOut: 'https://www.userapp-r.bontu-dev.io/login',
		  responseType: 'token'
		}
	  },
	  API: {
		endpoints: [
		  {
			name: 'ApiBontu',
			endpoint: 'https://api.bontu-dev.io/v1'
		  }
		]
	  }
	},
};

export default environment;